<template>
  <gf-base>
    <el-dialog v-loading="deleteModalLoading" :close-on-click-modal="false" :visible.sync="deleteModalVisible" append-to-body title="Delete Variant">
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-danger svg-icon-xxl">
            <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                <rect height="24" width="24" x="0" y="0"/>
                <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3"/>
                <rect fill="#000000" height="7" rx="1" width="2" x="11" y="9"/>
                <rect fill="#000000" height="2" rx="1" width="2" x="11" y="17"/>
              </g>
            </svg>
          </span>
        </template>
        You are trying to delete this product. All existing variant under this product will also be deleted. This action cannot be undone. Are you sure?
      </gf-alert>
      <template #footer>
        <gf-button class="mr-2" type="secondary" @click="deleteModalVisible = false">
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                    <rect height="2" rx="1" width="16" x="0" y="7"/>
                    <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="danger" @click="remove">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <rect height="24" width="24" x="0" y="0"/>
                  <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                  <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          Delete
        </gf-button>
      </template>
    </el-dialog>
    <gf-card :active-tab.sync="activeTab" :tab="tabs" @tab-click="tabClick">
      <template #toolbar>
        <gf-button class="mr-2" type="danger" @click="deleteModalVisible = true">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sx">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <rect height="24" width="24" x="0" y="0"/>
                  <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                  <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          Delete
        </gf-button>
        <gf-button type="secondary" @click="back">
          <template slot="icon">
            <span class="svg-icon svg-icon-sx">
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                  <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                </g>
              </svg>
            </span>
          </template>
          Back
        </gf-button>
      </template>
      <router-view :active.sync="activeTab"></router-view>
    </gf-card>
  </gf-base>
</template>

<script>
// services
import ProductService from '@/services/v1/ProductVariant'

export default {
  data () {
    return {
      activeTab: 1,
      tabs: [
        { id: 1, label: 'Details', routeName: 'product-variant-detail' },
        { id: 2, label: 'Pack & Bundles', routeName: 'product-variant-pack' },
        // { id: 3, label: 'Price Movement', routeName: 'product-variant-price' },
        { id: 3, label: 'Stock Activity', routeName: 'product-variant-activity' }
      ],

      deleteModalVisible: false,
      deleteModalLoading: false,

      // main
      variant: {
        name: null,
        product: {
          name: null
        }
      },

      // services
      vrService: null
    }
  },
  methods: {
    back () {
      this.$router.back()
    },
    async remove () {
      try {
        this.deleteModalLoading = true
        const pdService = new ProductService(this.$route.params.id, this.$route.params.vid)
        await pdService.del()

        this.deleteModalVisible = false
        this.$message.success('Successfully deleted product')
        await this.$router.push({ name: 'product-list' })
      } catch (error) {
        this.$Error(error)
      } finally {
        this.deleteModalLoading = false
      }
    },
    tabClick (tab) {
      this.$router.push({ name: this.tabs[tab - 1].routeName })
    }
  }
}
</script>
